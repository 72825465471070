<template>
    <div id="fiche-mission-page" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            Clients
        </div>
        <div class="card form-group" id="mission">
            <div class="card-header bg-primary">
                <h5 class="m-0">Fiche de mission</h5>
            </div>
            <div class="card-body">
                <h5 class="card-title font-weight-bold nameBlock">M. Pupont André</h5>
                <div class="row align-items-center form-group">
                    <div class="col adressBlock">
                        379 rue de Lyon<br />
                        130015 MARSEILLE<br />
                        1er étage
                    </div>
                    <div class="col">
                        <div class="row justify-content-end no-gutters">
                            <div class="col-auto mr-3">
                                <a class="btn callTheContactBtn btn-success" href="#">
                                    <i class="fas fa-phone fa-2x"></i>
                                </a>
                            </div>
                            <div class="col-auto">
                                <a class="btn showTheMapBtn btn-danger" href="#">
                                    <i class="fas fa-map-marked-alt fa-2x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 border-bottom border-top pt-2 pb-2">
                        <div class="row">
                            <div class="col-4">
                                <img src="/img/fiche_mission/contrat.png" alt="" />
                            </div>
                            <div class="col">
                                <h5 class="text-danger font-weight-bold">Contrat</h5>
                                <span class="contratPlaceholder">3h, 1x / semaine</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 border-bottom pt-3 pb-2">
                        <div class="row">
                            <div class="col-4">
                                <img src="/img/fiche_mission/jours.png" alt="" />
                            </div>
                            <div class="col">
                                <h5 class="text-danger font-weight-bold">Jours</h5>
                                <span class="jourPlaceholder">3h, 1x / semaine</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-2 pb-2">
                        <div class="row">
                            <div class="col-4">
                                <img src="/img/fiche_mission/repartition.png" alt="" />
                            </div>
                            <div class="col">
                                <h5 class="text-danger font-weight-bold">Répartition</h5>
                                <span class="repartitionPlaceholder">3h, 1x / semaine</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-card id="task" no-body>
            <b-card-header class="cursor-pointer d-flex p-0">
                <b-button block v-b-toggle.accordion variant="primary" class="bg-primary pt-3">
                    <h5 class="m-0">Tâches <sup><span class="badge badge-danger">4</span></sup></h5>
                    <span :class="{ 'up': (collapseState) }"><i class="toggleElementIco fas fa-chevron-down"></i></span>
                </b-button>
            </b-card-header>
            <b-collapse id="accordion" visible accordion="my-accordion" v-model="collapseState" role="tabpanel">
                <b-card-body>
                    <div class="tachesPlaceholder">lorem ipsum klndfslknfdlknflknsdlkfnlkdsnflknsdlkfndlsknflkdsn lksdnlkdnfqlkdsnlkdsnf</div>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
    import router from '../../router'
    export default {
        name: "Mission",
        data () {
            return {
                collapseState: false
            }
        },
        mounted () {
            this.$emit('menu', 'clients')
        },
        methods: {
            getBack () {
                router.go(-1)
            }
        }
    }
</script>

<style scoped>
    #ariane {
        text-align: center;
        padding: 10px;
        position: fixed;
        font-size: 22px;
        top: 45px;
        left: 0;
        right: 0;
        z-index: 2;
    }
    @media (min-width: 992px) {
        #ariane {
            top: 80px;
        }
    }
    #ariane button {
        position: absolute;
        left: 15px;
        background: transparent;
        border: none;
    }
    #ariane button:focus {
        box-shadow: none;
    }
    #mission {
        margin-top: 3.5rem;
    }
    #task > .card-header > button > h5 {
        width: calc(100% - 28px);
        float: left;
        text-align: left;
    }
    #task > .card-header > button:focus {
        box-shadow: none;
    }
    .fa-chevron-down {
        transition: all 0.6s;
    }
    .up .fa-chevron-down {
        transform: rotate(180deg);
        transition: all 0.6s;
    }
</style>
